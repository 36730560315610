<template>
    <section class="ticket-view flex mb-72">
        <ImageModal :show="showModal" :source="imageSource" @closed="closedModal" />
        <div v-if="ticket" class="flex-1 mr-10">
            
            <div class="shadow p-16" style="min-height: 483px; background-color:#F8F8F8">
                <div class="mb-5 flex justify-between items-start">
                    <h2 class="text-3xl w-3/4 leading-normal">{{ ticket.subject }}</h2>
                    <div class="flex flex-col items-end">
                        <p class="text-2xl text-gray-400 font-semibold" style="white-space: nowrap">{{ ticket.customer }} <span v-if="ticket.email && !ticket.email.includes('marketplace')">({{ ticket.email }})</span></p>
                        <p class="text-2xl text-gray-400 font-semibold">{{ ticket.created }}</p>
                    </div>
                </div>
                <div class="flex items-center mb-10">
                    <input type="text" class="custom-email w-1/2 p-5 border-2 border-grey-400 bg-gray-50" v-model="customEmail" placeholder="E-Mail senden an">
                    <p v-if="ticket.removeInfo" class="text-2xl text-red-500 font-bold ml-10">Bitte ändern Sie die Adresse des Empfängers</p>
                </div>
                <div class="flex">
                    <details class="text-2xl">
                        <summary>Shortcuts</summary>
                        Use:
                        ctrl+b for <b>bold</b>
                        ctrl+u for <u>underline</u>
                        ctrl+i for <i>italics</i>
                    </details>
                    <div class="icon icon-star ml-auto" @click="toggleStar">
                        <svg :class="{'filled': isStarred}" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star">
                            <polygon points="12 2 15 8 21 9 17 14 18 21 12 18 6 21 7 14 3 9 9 8 12 2"></polygon>
                        </svg>
                    </div>
                </div>
                <div 
                    class="border-2 relative cursor-pointer border-grey-400">
                    <div v-if="makroContainsIBAN" class="containsIBAN">
                        Vorsicht, dieses Makro enthält IBAN!
                    </div>
                    <vue-dropzone 
                        ref="myVueDropzone"
                        id="dropzone"
                        @vdropzone-success="addedFile"
                        @vdropzone-removed-file="removeFile"
                        :options="dropzoneOptions"
                    ></vue-dropzone>
                    <!-- <editor 
                        @dragover="checkDrop"
                        apiKey="8spqxq12m8z598gogaydst2muefa6ygx32kigzgt05bazbbp"
                        placeholder="Antwort hier schreiben"
                        :value="selected"
                        @input="updateContent($event)"
                        :init="{
                        height: 324,
                        menubar: false,
                        branding: false,
                        toolbar_location: 'bottom',
                        resize: true,
                        skin: 'naked',
                        ref: 'editori',
                        statusbar: false,
                        plugins: [
                        'advlist autolink lists link preview anchor',
                        'code fullscreen',
                        'paste code contextmenu',
                        ],
                        contextmenu: false,
                        init_instance_callback: this.checkDrop,
                        toolbar:
                        'undo redo formatselect link bold italic backcolor forecolor \
                            alignleft aligncenter alignright alignjustify \
                            bullist numlist outdent indent \
                            fullscreen',
                        }"
                    >
                </editor> -->
                <div @dragover="checkDrop">
                    <TextEditor ref="editori" @input="updateContent($event)" placeholder="Antwort hier schreiben" v-model="selected"></TextEditor>
                </div>
                <button type="button" class="show-files" @click="toggleDropzone">{{ `Abgelegte Dateien ${!showDrop ? 'anzeigen' : 'ausblenden'}` }}</button>
                    <button class="file-btn" type="button" @click="uploadFiles" />
                </div>
                <p class="text-red-500 font-bold text-2xl mt-10" v-if="error">{{ error }}</p>
                <div class="text-2xl mt-4">
                    <span>Selected: </span>
                    <select class="border border-gray-300 h-12 bg-gray-50" v-model="selectedCategory">
                        <option disabled value="">Please select one</option>
                        <option value="prompt_technical">Technical</option>
                        <option value="prompt_product">Product Advice</option>
                        <option value="prompt_order">Order Status</option>
                        <option value="prompt_faq">FAQ</option>
                    </select>
                    <button class="border border-gray-300 h-12 ml-2 px-3" @click="sendQuestion" :disabled="isLoading">{{ isLoading? 'Loading...':'Generate Answer' }}</button>
                    <button class="border border-gray-300 h-12 ml-2 px-3" @click="cancelRequest" :disabled="!isLoading">Cancel</button>
                    <span v-if="isLoading" class="ml-4">{{ counter }}..</span>
                </div>
            </div>
            <div class="shadow p-16 mt-20" style="background-color:#F8F8F8">
                <h2 class="text-3xl font-bold w-3/4">Konversationen</h2>
                <div class="conversation relative mt-14" v-for="comment in comments" :key="comment.id">
                    <span :class="comment.isStaff ? 'absolute top-3 left-0 w-14 h-2 bg-green-600' : 'absolute top-3 left-0 w-14 h-2 bg-red-600'"></span>
                    <div class="pl-24">
                        <h3 class="text-3xl font-semibold">{{ getAuthorName(comment.isStaff) }} <span class="ml-10 text-2xl text-gray-400 font-normal">{{ comment.created }}</span></h3>
                        <div class="text-2xl mt-5 w-3/4" style="white-space: pre-line" v-html="comment.content">
                        </div>
                        <!-- <VueMarkdown class="text-2xl mt-5 w-3/4" :html="true" :source="comment.content"></VueMarkdown> -->
                        
                    </div>
                        <div class="attachments" style="gap: 10px" v-if="comment.attachments">
                            <div class="repeated" v-for="item in comment.attachments" :key="item">
                                <img v-if="!item.endsWith('pdf')" @click.stop="imageClicked(item)"  :src="item">
                                <a class="transition inline-block hover:bg-gray-200 mt-5 shadow border-2 py-3 px-5 text-2xl font-medium" v-if="item.endsWith('pdf')" :href="item"><i class="mr-5 far fa-file-pdf"></i> {{ getFileName(item) }}</a>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <!-- <div v-if="ticket" class="max-w-3xl w-full ml-20"> -->
            <!-- <div v-if="gotOrderData && ticketOrderInfo.length" class="mb-20 bg-white shadow p-16">
                <div v-for="shipping in ticketOrderInfo" class="mb-10" :key="shipping.shippingId">
                    <p class="text-3xl text-gray-700 font-bold mb-5">{{ shipping.shipper }}: {{ shipping.shippingId }}</p>
                    <ul>
                        <li class="dpd-result text-2xl" v-for="event in shipping.events" :key="event.label">
                            <div class="flex">
                                <span style="width: 126px" class="inline-block mr-5">{{ event.date }}</span>
                                <span class="font-normal"> {{ event.label }} </span>
                            </div>
                            <div v-if="event.additional">
                                <p class="font-normal" v-for="code in event.additional" :key="code">
                                    {{ code }}<br>
                                </p>
                            </div>
                        </li>
                    </ul>
                    <p class="text-2xl text-red-500" v-if="!shipping.events.length">Daten nicht gefunden!</p>
                </div>
            </div> -->
            <!-- <div class="bg-white shadow relative p-16">
                <h2 class="text-3xl font-bold w-3/4">Details zur Bestellung</h2>
                <p class="text-2xl leading-normal mt-4 text-red-600" v-if="!loading && !amazonDetails.amazon_id">Für dieses Ticket ist keine Auftragsinfo verfügbar. Wahrscheinlich gibt es keine zugehörige Bestellung oder die Bestellung ist älter als 30 Tage.</p>
                <div v-if="amazonDetails.amazon_id" class="flex mt-10">
                    <ul class="list-none">
                        <li class="text-2xl bg-gray-100 p-5">Amazon Order Id: </li> -->
                        <!-- <li class="text-2xl p-5">Order Status: </li>
                        <li class="text-2xl bg-gray-100 p-5">Purchase Date: </li>
                        <li class="text-2xl p-5">Amount: </li>
                        <li class="text-2xl bg-gray-100 p-5">Delivery Level: </li>
                        <li class="text-2xl p-5">Earliest Delivery Date: </li>
                        <li class="text-2xl bg-gray-100 p-5">Latest Delivery Date: </li>
                        <li class="text-2xl p-5">Ship Date: </li>
                        <li class="text-2xl bg-gray-100 p-5">Ship Service Level: </li>
                        <li class="text-2xl p-5">Fulfillment Channel: </li>
                        <li class="text-2xl bg-gray-100 p-5">City: </li>
                        <li class="text-2xl p-5">State Region: </li>
                        <li class="text-2xl bg-gray-100 p-5">Postal Code: </li>
                        <li class="text-2xl p-5">Country Code</li> -->
                    <!-- </ul>
                    <ul class="amazon-details list-none">
                        <li class="text-2xl bg-gray-100 p-5"><a :href="amazonDetails.amazon_link" target="_blank" class="no-underline text-blue-600">{{ amazonDetails.amazon_id }}</a></li> -->
                        <!-- <li class="text-2xl p-5">{{ amazonDetails.shipp_status }}</li>
                        <li class="text-2xl bg-gray-100 p-5">{{ amazonDetails.purchase_date }}</li>
                        <li class="text-2xl p-5">{{ amazonDetails.amount }}</li>
                        <li class="text-2xl bg-gray-100 p-5">{{ amazonDetails.delivery_level }}</li>
                        <li class="text-2xl p-5">{{ amazonDetails.early_date_delivery }}</li>
                        <li class="text-2xl bg-gray-100 p-5">{{ amazonDetails.latest_date_delivery }}</li>
                        <li class="text-2xl p-5">{{ amazonDetails.shipped_date }}</li>
                        <li class="text-2xl bg-gray-100 p-5">{{ amazonDetails.shipservice_level }}</li>
                        <li class="text-2xl p-5">{{ amazonDetails.fullfillment }}</li>
                        <li class="text-2xl bg-gray-100 p-5">{{ amazonDetails.city }}</li>
                        <li class="text-2xl p-5">{{ amazonDetails.state_region }}</li>
                        <li class="text-2xl bg-gray-100 p-5">{{ amazonDetails.postal_code }}</li>
                        <li class="text-2xl p-5">{{ amazonDetails.country_code }}</li> -->
                    <!-- </ul>
                </div>   
            </div> -->

            <!-- <div class="last bg-white shadow relative p-16 mt-20">
                <h2 class="text-3xl font-bold w-3/4">RetourenSohn</h2>
                <div class="flex w-full items-stretch mt-10">
                    <input type="text" autocomplete="off" v-model="retourenSearch" class="focus:outline-none flex-1 p-5 border-2 border-grey-400" placeholder="Name/Billbee Id">
                    <button type="button" @click="searchForRetouren" :disabled="!retourenSearch" class="primary-btn text-white text-2xl font-bold"><i class="fas fa-search"></i></button>
                </div>
                <div class="results" v-if="retourenResults">
                    <p class="text-2xl mb-2 mt-5">Name: <span class="font-bold">{{ retourenResults.name }}</span></p>
                    <p class="text-2xl mb-2">Adresse: <span class="font-bold">{{ retourenResults.address }}</span></p>
                    <p class="text-2xl mb-2">Kundennummer: <span class="font-bold">{{ retourenResults.customer }}</span></p>
                    <p class="text-2xl mb-2">Status: <span class="font-bold">{{ retourenResults.status }}</span></p>
                    <p class="text-2xl mb-2">BB_ID: <span class="font-bold">{{ retourenResults.bb_id }}</span></p>
                    <p class="text-2xl mb-2">Eingegangen: <span class="font-bold">{{ new Date(retourenResults.created_at).toLocaleString() }}</span></p>
                    <p class="text-2xl mb-2">Verändert: <span class="font-bold">{{ new Date(retourenResults.updated_at).toLocaleString() }}</span></p>
                    <p class="text-2xl mb-2">AG: <span class="font-bold">{{ retourenResults.ag }} ({{ retourenResults.product }})</span></p>
                    <p class="text-2xl mb-2">Füllmenge: <span class="font-bold">{{ retourenResults.size }}</span></p>
                    <p class="text-2xl mb-2">RAL: <span class="font-bold">{{ retourenResults.ral }}</span></p>
                    <p class="text-2xl mb-2">Glanzgrad: <span class="font-bold">{{ retourenResults.gloss }}</span></p>
                    <p class="text-2xl mb-2">Menge: <span class="font-bold">{{ retourenResults.quantity }}</span></p>
                </div>
            </div> -->
            <!-- <div class="last bg-white shadow relative p-16 mt-20">
                <h2 class="text-3xl font-bold w-3/4">Gescannte</h2>
                <div class="flex w-full items-stretch mt-10">
                    <input type="text" autocomplete="off" v-model="scanSearch" class="focus:outline-none flex-1 p-5 border-2 border-grey-400" placeholder="Rechnungs-Nummer">
                    <button type="button" @click="searchForScan" :disabled="!scanSearch" class="primary-btn text-white text-2xl font-bold"><i class="fas fa-search"></i></button>
                </div>
                <p v-if="scanResult" class="text-2xl font-medium mt-5 leading-normal" v-html="scanResult"></p>
            </div> -->
            <!-- <div class="last bg-white shadow relative p-16 mt-20">
                <h2 class="text-3xl font-bold w-3/4">DPD suche</h2>
                <div class="flex w-full items-stretch mt-10">
                    <input type="text" autocomplete="off" v-model="dpdSearch" class="focus:outline-none flex-1 p-5 border-2 border-grey-400" placeholder="Bestellung">
                    <button type="button" @click="searchForDPD" :disabled="!dpdSearch" class="primary-btn text-white text-2xl font-bold"><i class="fas fa-search"></i></button>
                </div>
                <p v-if="dpdError" class="text-xl text-red-500 mt-5">{{ dpdError }}</p>
                <ul v-if="dpdResult">
                    <li class="dpd-result text-2xl font-bold mt-5" v-for="result in dpdResult" :key="result.label">
                        <div class="flex">
                            <span style="width: 126px" class="inline-block mr-5">{{ result.date }}</span>
                            <span> {{ result.label }} </span>
                        </div>
                        <div v-if="result.additional">
                            <p v-for="code in result.additional" :key="code">
                                {{ code }}<br>
                            </p>
                        </div>
                    </li>
                </ul>
            </div> -->
            <!-- <div class="last bg-white shadow relative p-16 mt-20">
                <h2 class="text-3xl font-bold w-3/4">DHL suche</h2>
                <div class="flex w-full items-stretch mt-10">
                    <input type="text" autocomplete="off" v-model="dhlSearch" class="focus:outline-none flex-1 p-5 border-2 border-grey-400" placeholder="Bestellung">
                    <button type="button" @click="searchForDHL" :disabled="!dhlSearch" class="primary-btn text-white text-2xl font-bold"><i class="fas fa-search"></i></button>
                </div>
                <p v-if="dhlError" class="text-xl text-red-500 mt-5">{{ dhlError }}</p>
                <div v-for="shipping in dhlResult" class="mt-10" :key="shipping.shippingId">
                    <ul>
                        <li class="dpd-result text-2xl" v-for="event in shipping.events" :key="event.label">
                            <div class="flex">
                                <span style="width: 126px" class="inline-block mr-5">{{ event.date }}</span>
                                <span class="font-normal"> {{ event.label }} </span>
                            </div>
                            <div v-if="event.additional">
                                <p class="font-normal" v-for="code in event.additional" :key="code">
                                    {{ code }}<br>
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div> -->
            <!-- <div class="bg-white shadow p-16 mt-20">
                <h2 class="text-3xl font-bold w-3/4">Durchschnittliche Lieferzeit</h2>
                <p class="text-3xl mt-10 text-center">Voraussichtliche Lieferung:</p>
                <p class="text-4xl mt-10 text-center text-green-600 font-semibold">ca. {{ averageDays }} Tage</p>
            </div>
            <div class="bg-white shadow p-16 mt-20 stornobox">
                <h2 class="text-3xl font-bold">
                    <button @click="showStornoInput = !showStornoInput" type="button" class="focus:outline-none hover:bg-blue-300 transition bg-blue-400 py-2 px-3 mr-5 text-white font-bold text-2xl">
                        <i :class="`fas fa-check ${showStornoInput ? 'text-white' : 'text-blue-400'}`"></i>
                    </button>
                    Storno nicht mehr versenden</h2>
                <input v-if="showStornoInput" type="text" class="stornoinput mt-10 w-full p-5 border-2 border-grey-400" v-model="stornoId">
            </div> -->
        <!-- </div> -->
        <div v-if="ticket" class="bottom-ticket fixed flex justify-between p-10 w-full left-0 bottom-0" style="background-color:#F8F8F8; box-shadow: 0 -5px 40px -5px rgba(0,0,0, 5%);">
            <i class="absolute lightning text-gray-400 text-4xl fas fa-bolt"></i>
            <div class="flex flex-1 selections">
                <v-select
                class="filter-white max-w-3xl text-2xl w-full custom-select-bg"
                placeholder="Makro ausführen"
                :options="makrosTitles"
                position="top"
                @input="updateValue"
                ></v-select>
                <v-select
                class="filter-white max-w-md ml-10 text-2xl w-full custom-select-bg"
                placeholder="Verschieben an"
                :options="tagsNames"
                position="top"
                @input="updateAgent"
                ></v-select>
                <p class="text-red-500 font-bold text-2xl ml-10 mt-5" v-if="agentError">{{ agentError }}</p>
            </div>
            <div class="flex relative">
                <ul v-show="toggleDropUp" v-click-outside="onOutside" class="transition absolute bottom-full mb-2 rounded-md w-full dropup list-none" style="background: #4F98C1">
                    <li @click="changeSubmit('open')" class="p-7 text-2xl font-bold text-white hover:bg-blue-400 transition-colors cursor-pointer">Einreichen als Offen</li>
                    <li @click="changeSubmit('hold')" class="p-7 text-2xl font-bold text-white hover:bg-blue-400 transition-colors cursor-pointer">Einreichen als Wartend</li>
                    <li @click="changeSubmit('solved')" class="p-7 text-2xl font-bold text-white hover:bg-blue-400 transition-colors cursor-pointer">Einreichen als Gelöst</li>
                </ul>
                <button type="button" @click="submitAs" :disabled="submitType == 'solved' && disableSolved" class="focus:outline-none primary-btn" style="margin-right: 5px;">{{ submitTypeLabel }}
                </button>
                <button type="button" @click.stop="toggleDropUp = !toggleDropUp" class="focus:outline-none primary-btn" style="margin-right: 0px;">
                    <i class="fas text-4xl fa-chevron-up"></i>
                </button>
            </div>
        </div>
    </section>
</template>

<style lang="scss">
    .star {
        width: 8rem;
    }
    .icon-star svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
        // transition: fill 0.3s ease;
    }
    .icon-star svg.filled {
        fill: black;
        stroke: black;
    }

    .v-select.custom-select-bg {
        background-color: #f9fafb;
    }
    .v-select.custom-select-bg .vs__dropdown-toggle {
        background-color: #f9fafb;
    }
    .v-select.custom-select-bg .vs__dropdown-menu {
        background-color: #f9fafb;
    }
    .editor {
        border: 1px solid gray;
        font-size: 15px;
        width: 100%;
        height: 200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        outline: none;
        overflow-y: auto;
        cursor: text;
    }
    .bottom-ticket .dropup {
        z-index: 1 !important;
    }
    .dpd-result {
        background: rgb(243, 244, 246);
        padding: 5px;
        
        span {
            line-height: 24px;
        }
    }
    .dpd-result span:last-child {
        flex: 1;
    }
    .dpd-result:nth-child(even) {
        background: #fff;
    }
    .vue-dropzone {
        padding: 0px;
    }
    .dz-message {
        margin: 0px !important;
    }
    #dropzone {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        opacity: 0;
        pointer-events: none;
    }
    .show-files {
        position: absolute;
        bottom: -3rem;
        right: 0;
        font-size: 1.3rem;
        color: rgb(79, 152, 193);
        text-decoration: underline;
        &:focus {
            outline: none;
        }
    }
    input[type=text] {
        color: rgba(34,47,62,.7);
        padding-left: 15px;
        font-size: 15px;
    }
    .vs__search, .vs__search:focus {
        padding:  1rem 4rem !important;
    }
    .v-select.drop-up.vs--open .vs__dropdown-toggle {
        border-radius: 0 0 4px 4px;
        border-top-color: transparent;
        border-bottom-color: rgba(60, 60, 60, 0.26);
    }

    [data-popper-placement='top'] {
        border-radius: 4px 4px 0 0;
        border-top-style: solid;
        border-bottom-style: none;
        box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15)
    }
    #vs4__listbox {
        font-size: 1.5rem !important;
    }
    .lightning {
        top: 4rem;
        left: 4rem;
    }
    .vs__selected {
        padding-left: 4rem !important;
    }
    .vs__dropdown-menu {
        top: unset !important;
        bottom: 5rem;
        // position: fixed;
        left: 0;
        z-index: 9999 !important;
    }
    .vs__dropdown-toggle {
        border: 1px solid rgba(60,60,60,.26) !important;
    }
    .attachments {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 15px;
        img {
            width: 100%;
            height: 25rem;
            object-fit: cover;
            cursor: pointer;
        }
    }
    .tox .tox-editor-header {
        z-index: 0 !important;
    }
    .amazon-details li {
        min-height: 4.5rem;
    }
    .file-btn {
        position: absolute;
        bottom: 1rem;
        right: 0;
    }
    .file-btn {
        cursor: pointer;
        width: 180px;
        height: 34px;
        overflow: hidden;
    }

    .file-btn:before {
        width: 158px;
        height: 32px;
        font-size: 18px;
        line-height: 32px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f0c6";
        color: #6b6f75;
        display: inline-block;
        text-align: right;
        padding-top: 8px;
        transition: color .5s ease;
    }

    .file-btn:hover:before {
        color: #b4bcc7;
    }

    .file-btn:focus {
        outline: none;
    }

    button[disabled] {
        background: rgba(79, 152, 193, 0.7);
        cursor: not-allowed;
    }

    img[alt=facebook] {
        width: 30px;
    }
    img[alt=twitter] {
        width: 30px;
    }
    img[alt=pinterest] {
        width: 30px;
    }
    img[alt=instagram] {
        width: 30px;
    }

    code {
        max-width: 100%;
        display: inline-block;
        white-space: normal;
    }

    .containsIBAN {
        padding: 5px;
        font-size: 20px;
        border: 2px solid red;
        background-color: rgba(red, 0.2);
        color: red;
    }
    @media screen and (max-width: 576px) {
        .ticket-view {
            flex-direction: column;
            &>div {
                margin-right: 0px;
                margin-left: 0px;
            }
            .custom-email {
                max-width: 100%;
                font-size: 15px;
                width: 100%;
                &::placeholder {
                    font-size: 12px;
                }
            }
            &>div:nth-child(2) {
                max-width: 100%;
                margin-top: 5rem;
            }
            .conversation {
                .text-2xl {
                    font-size: 15px;
                    line-height: 20px;
                    width: 100%;
                }
            }
        }
        .bottom-ticket {
            padding-left: 30px;
            padding-right: 30px;
            flex-direction: column;
            .selections {
                flex-direction: column;
                .filter-white {
                    max-width: 100%;
                }
                .filter-white:nth-child(2) {
                    margin-left: 0px;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                
            }
            .lightning {
                top: 4.5rem;
                left: 7rem;
            }
        }
        .last {
            margin-bottom: 15rem;
        }
    }
</style>

<script>
//   import Editor from '@tinymce/tinymce-vue'; 
  import TextEditor from '../components/TextEditor.vue';
  import { createPopper } from '@popperjs/core';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  import axios from 'axios';

  export default {
    data() {
        return {
            ticketId: this.$route.params.id,
            showStornoInput: false,
            error: '',
            agentError: '',
            toggleDropUp: false,
            selected: '',
            averageDays: 0,
            amazonDetails: {},
            stornoId: '',
            showModal: false,
            imageSource: '',
            contentValue: '',
            files: '',
            filesDropzone: '',
            agent: '',
            disableSolved: false,
            submitType: 'solved',
            customEmail: '',
            retourenSearch: '',
            retourenResults: null,
            scanSearch: '',
            scanResult: '',
            dropzoneOptions: {
                // url: process.env.baseURL + '/api/v1/uploads',
                url: 'https://ourdesk.io/api/v1/uploads',
                maxFilesize: 10,
                headers: { 'My-Awesome-Header': 'header value' },
                addRemoveLinks: true,
                dictDefaultMessage: 'Datei hochladen',
                dictRemoveFile: 'Datei entfernen'
            },
            showDrop: false,
            dpdSearch: '',
            dpdError: '',
            dpdResult: [],
            dhlSearch: '',
            dhlError: '',
            dhlResult: [],
            gotOrderData: false,
            makroContainsIBAN: false,
            selectedCategory: null,
            isLoading: false,
            cancelTokenSource: null,
            counter: 0,
            content: '',
            isStarred: false,
            starredTickets: []
        }
    },
    components: {
    //   editor: Editor, 
      TextEditor,
      vueDropzone: vue2Dropzone
    },
    async created() {
        const { data } = await axios.get("https://new-era.io/api/v2/average/stats/today?number=7");
        const zittauSum = data.data.zittau.reduce((a,b) => a + b.average, 0);
        const halleSum = data.data.halle.reduce((a,b) => a + b.average, 0);
        this.averageDays = Math.floor(((zittauSum / 7) + (halleSum / 7))/2);
    },
    mounted() {
        this.updateStarStatus();
        // console.log(this.ticketId)
        this.setLoading(true);
        this.getMakros();
        this.getTicket(this.$route.params.id);
        // setTimeout(() => {
        //     if(this.ticket == null) {
        //         this.$router.push('/dashboard');
        //     }
        // }, 2000);
        this.getTicketComments(this.$route.params.id);
        let content = window.localStorage.getItem(this.$route.params.id);
        if(content) {
            this.selected = content;
        }
    },
    methods: {
        updateAgent(value) {
            for(let key in this.tags) {
                if(this.tags[key] == value) {
                    this.agent = key;
                }
            }
            this.agentError = '';
            if((value && value === 'Weiterleitung an Finn') || (value && value === 'Weiterleitung an Lafazit') || (value && value === 'Weiterleitung an Mahmud')) {
                this.disableSolved = false; // needs to be addedt
            } else {
                this.disableSolved = true;
            }
        },
        getFileName(file) {
            return file.split('?name=')[1];
        },
        uploadFiles() {
            document.querySelector("#dropzone").click();
        },
        ...mapActions(['getOrderInformation', 'getMakros', 'moveTo', 'sendEmail', 'getTicket', 'getAgents', 'getTicketComments', 'addComment', 'removeTicket']),
        ...mapMutations(['setLoading']),
        withPopper (dropdownList, component, {width}) {
            dropdownList.style.width = width;
            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: 'top',
                modifiers: [
                {
                    name: 'offset', options: {
                        offset: [0, -1]
                    }
                },
                {
                    name: 'toggleClass',
                    enabled: true,
                    phase: 'write',
                    fn ({state}) {
                        component.$el.classList.toggle('drop-up', state.placement === 'top')
                    },
                }]
            });
            return () => popper.destroy();
        },
        getAuthorName(isStaff) {
            if(isStaff) {
                return 'Farbenlöwe Agent';
            } else {
                // return decodeURIComponent(escape(this.ticket.customer)) || this.ticket.customer;
                return this.ticket.customer;
            }
        },
        onOutside() {
            this.toggleDropUp = false;
        },
        updateValue(value) {
            if(value) {
                this.selected += this.makros.filter(el => el.title == value)[0].content;
                this.contentValue = this.selected;

                if (this.contentValue.includes("IBAN: DE72200700240079144200")) {
                    this.makroContainsIBAN = true;
                    console.log(this.makroContainsIBAN);
                }
                else {
                    this.makroContainsIBAN = false;
                    console.log(this.makroContainsIBAN);
                }
            }
        },
        getAmazonDetails() {
            this.amazonDetails.amazon_link = `https://sellercentral.amazon.de/orders-v3/order/${this.ticket.fields?.find(element => element.id ===  360026004057).value}`;
            this.amazonDetails.amazon_id = this.ticket.fields?.find(element => element.id ===  360026004057).value;
            this.stornoId = this.amazonDetails.amazon_id;
            if(this.ticket.subject.includes("#")) {
                const orderId = this.ticket.subject.slice(this.ticket.subject.indexOf('#'), this.ticket.subject.indexOf('#') + 12);
                this.getOrderInformation(orderId);
            } else {
                this.getOrderInformation(this.ticket.fields?.find(element => element.id ===  360026004057).value);
            }
            this.gotOrderData = true;
        },
        imageClicked(source) {
            this.showModal = true;
            this.imageSource = source;
        },
        closedModal() {
            this.showModal = false;
            this.imageSource = '';
        },
        updateContent(value) {
            this.selected = value;
            this.contentValue = value;
            if(value.length) {
                window.localStorage.setItem(this.$route.params.id, value);
            }
        },
        changeSubmit(type) {
            this.submitType = type;
            this.onOutside();
        },
        submitAs() {
            switch(this.submitType) {
                case 'solved': {
                    this.toggleStar('solved')
                    if(this.customEmail) {
                        const fileData = new FormData();
                        // for(let i = 0; i < this.files.length; i++) {
                        //     fileData.append('files', this.files[i]);
                        // }
                        if(this.filesDropzone.length) {
                            for(let i = 0; i < this.filesDropzone.length; i++) {
                                fileData.append('files', this.filesDropzone[i]);
                            }   
                        }
                        fileData.append('to', this.customEmail);
                        fileData.append('text', this.contentValue.replace(/<[^>]*>/g, ''),);
                        fileData.append('html', this.contentValue);
                        fileData.append('requester', this.ticket.customer);
                        this.sendEmail({
                            id: this.ticket.id,
                            content: fileData
                        });
                    } else {
                        // if(!this.contentValue.length && this.ticket.fromAmazon) {
                        //     this.error = 'Antwort kann nicht leer sein';
                        //     return;
                        // }
                        if(this.ticket.fromAmazon && new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(this.contentValue)) {
                            this.error = 'Sie können keine Links an amazon-Kunden senden! Bitte überprüfen Sie Ihre Antwort erneut.';
                            return;
                        }
                        const fileData = new FormData();
                        // for(let i = 0; i < this.files.length; i++) {
                        //     fileData.append('files', this.files[i]);
                        // }
                        if(this.filesDropzone.length) {
                            for(let i = 0; i < this.filesDropzone.length; i++) {
                                fileData.append('files', this.filesDropzone[i]);
                            }   
                        }
                        if(this.agent) {
                            this.moveTo({
                                id: this.ticket.id,
                                to: this.agent
                            });
                        }
                        fileData.append('text', this.contentValue.replace(/<[^>]*>/g, ''));
                        fileData.append('content', this.contentValue);
                        fileData.append('subject', this.ticket.subject);
                        fileData.append('email', this.ticket.email);
                        fileData.append('fromAmazon', this.ticket.fromAmazon);
                        fileData.append('fromMarketPlace', !!this.ticket.fields.find(el => el.id == 360025631998).value);
                        fileData.append('fromEbay', this.ticket.fromEbay);
                        fileData.append('requester', this.ticket.customer);
                        if(this.showStornoInput && this.stornoId.length) {
                            fileData.append('stornoid', this.stornoId);
                        }
                        if(!this.removeInfo) {
                            this.addComment({
                                id: this.ticket.id,
                                content: fileData
                            }); 
                        }
                    }
                    const content = window.localStorage.getItem(this.$route.params.id);
                    if(content) {
                        window.localStorage.removeItem(this.$route.params.id);
                    }
                    this.removeTicket(this.$route.params.id);
                    break;
                }
                case 'open': {
                    if(!this.agent && !this.customEmail) {
                        this.agentError = 'Bitte wählen Sie einen Agenten';
                        return;
                    }

                    let existingTickets = localStorage.getItem('moved-tickets') || '';
                    
                    localStorage.setItem('moved-tickets', existingTickets + this.$route.params.id + ',')
                    this.moveTo({
                        id: this.ticket.id,
                        to: this.agent
                    });
                    
                }
            }
        },
        async searchForRetouren() {
            // const data = await axios.get(process.env.baseURL + `/api/v1/features/retouren?name=${this.retourenSearch}`);
            const data = await axios.get(`https://ourdesk.io/api/v1/features/retouren?name=${this.retourenSearch}`);
            this.retourenResults = data.data.results;
        },
        async searchForScan() {
            // const data = await axios.get(process.env.baseURL + `/api/v1/features/scan?id=${this.scanSearch}`);
            const data = await axios.get(`https://ourdesk.io/api/v1/features/scan?id=${this.scanSearch}`);
            const result = data.data.data;
            if (result.length == 0) {
                this.scanResult = 'Nicht gefunden!'
            } else {
                if (result[0].is_storno == 'false') {
                    result[0].is_storno = 'Nein'
                } else {
                    result[0].is_storno = 'Ja'
                }
                let str = result[0].date
                let date = new Date(str).toLocaleString()
                result[0].date = date
                this.scanResult = result[0].ls_id + ' wurde ' + result[0].date + ' gescannt <span class="inline-block font-bold"> Storno: ' + result[0].is_storno + '</span>';
            }
        },
        checkDrop() {
            document.querySelector("#dropzone").style.opacity = 1;
            document.querySelector("#dropzone").style.pointerEvents = 'all';
        },
        addedFile(file) {
            if(Array.isArray(this.filesDropzone)) {
                this.filesDropzone.push(file);
            } else {
                this.filesDropzone = [file];
            }
            document.querySelector("#dropzone").style.opacity = 1;
            this.showDrop = true;
            // document.querySelector("#dropzone").style.pointerEvents = 'none';
        },
        removeFile(file) {
            this.filesDropzone.splice(this.filesDropzone.indexOf(file), 1);
        },
        toggleDropzone() {
            this.showDrop = !this.showDrop;
            if(this.showDrop) {
                document.querySelector("#dropzone").style.opacity = 1;
                document.querySelector("#dropzone").style.pointerEvents = 'all';
            } else {
                document.querySelector("#dropzone").style.opacity = 0;
                document.querySelector("#dropzone").style.pointerEvents = 'none';
            }
        },
        async searchForDPD() {
            this.dpdResult = [];
            this.dpdError = '';
            // const { data } = await axios.get(process.env.baseURL + `/api/v1/features/dpd?id=${this.dpdSearch}`);
            const { data } = await axios.get(`https://ourdesk.io/api/v1/features/dpd?id=${this.dpdSearch}`);
            if(data.message) {
                this.dpdError = data.message;
            } else {
                this.dpdResult = data;
            }
        },
        async searchForDHL() {
            this.dhlResult = [];
            this.dhlError = '';
            // const { data } = await axios.get(process.env.baseURL + `/api/v1/features/order-info?id=${this.dhlSearch}`);
            const { data } = await axios.get(`https://ourdesk.io/api/v1/features/order-info?id=${this.dhlSearch}`);
            if(data.message) {
                this.dhlError = data.message;
            } else {
                this.dhlResult = data;
            }
        },
        async sendQuestion() {
            const customerComment = this.comments.filter(comment => !comment.isStaff);
            const lastCustomerComment = (customerComment[0]).content_text;
            const requestBody = {
                prompt: lastCustomerComment, // get comment(email) from the latest interaction 
                operation: this.selectedCategory, // the type of prompt
                isAmazon: this.ticket.fromAmazon,
            };

            try {
                this.isLoading = true;
                if(this.selectedCategory){
                    this.startCounter()
                    this.cancelTokenSource = axios.CancelToken.source();
                    const response = await axios.post("https://new-era.digital/chatgpt/customerService",  
                    requestBody,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        // timeout: 5000,
                        cancelToken: this.cancelTokenSource.token,
                    });
                    if (response.status == 200) {
                        const data = response.data;
                        this.updateContent(data.answer)
                    } else {
                        console.error("API request failed");
                    }
                }
            } catch (error) {
                if (axios.isCancel(error)) {
                console.log("Request canceled:", error.message);
                } else {
                console.error(error);
                }
            } finally {
                this.isLoading = false;
                clearInterval(this.counterInterval)
            }
        },
        startCounter(){
            this.counter = 0
            this.counterInterval = setInterval(() => {
                this.counter++;
            }, 1000);
        },
        cancelRequest() {
            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel("Request canceled by user");
                clearInterval(this.counterInterval)
                this.isLoading = false;
            }
        },
        toggleStar(action = null) {
            if (action === 'solved' && !this.isStarred) {
                return;
            }
            this.isStarred = !this.isStarred;
            let starredTickets = JSON.parse(localStorage.getItem('favouritedTickets')) || [];
            if (!Array.isArray(starredTickets)) {
                starredTickets = [];
            }

            const ticketData = {
                ticketId: this.ticket.id,
                ticketCustomer: this.ticket.customer,
                ticketSubject: this.ticket.subject,
                ticketStarred: this.isStarred
            };

            if (this.isStarred) {
                if (!starredTickets.some(ticket => ticket.ticketId === this.ticket.id)) {
                    starredTickets.push(ticketData);
                }
            } else {
                starredTickets = starredTickets.filter(ticket => ticket.ticketId !== this.ticket.id);
            }
            localStorage.setItem('favouritedTickets', JSON.stringify(starredTickets));
            this.$root.$emit('favourite-updated', { ticket: ticketData, isStarred: this.isStarred });
        },
        updateStarStatus() { 
            let starredTickets = JSON.parse(localStorage.getItem('favouritedTickets')) || [];
            if (!Array.isArray(starredTickets)) {
                starredTickets = [];
            }
            this.isStarred = starredTickets.some(ticket => ticket.ticketId === this.ticket.id);
        },
    },
    computed: {
        ...mapGetters(['ticketOrderInfo', 'makros', 'ticket', 'comments', 'loading', 'agents', 'loggedUser']),
        makrosTitles() {
            if (this.ticket.fromAmazon || (this.ticket.email != null && this.ticket.email.includes("amazon"))) {
                return this.makros.filter(el => !el.title.includes("Direktrechnung")).map(el => el.title);
            } else {
                return this.makros.map(el => el.title);
            }
        },
        agentsNames() {
            return this.agents.filter(el => el._id != this.loggedUser._id).map(el => el.name.split(' ')[0]);
        },
        // ...mapMutations(['removeTicket']),
            submitTypeLabel() {
                switch (this.submitType) {
                case 'solved': {
                    // this.removeTicket(this.ticketId); // Call the removeTicket mutation with the ticketId parameter
                    return 'Einreichen als Gelöst';
                }
                case 'open': {
                    return 'Einreichen als Offen'
                }
                case 'hold': {
                    return 'Einreichen als Wartend'
                }
            }
        },
        tags() {
            return {
                va_elton: 'Elton VA',
                farbenlowe_kosovo1: 'Elton Amazon',
                va_suad: 'Elton Rexhepi VA',
                farbenlowe_kosovo2: 'Elton Rexhepi Amazon',
                va_falk: 'Falk VA',
                va_sam: 'Sam VA',
                farbenlowe_germany1: 'Sam Amazon',
                farbenlowe_germany2: 'Falk Amazon',
                va_dena: 'Dena VA',
                farbenlowe_kosovo: 'Dena Amazon',
                facebook_de: 'Facebook',
                info: 'Info',
                va: 'Lisa',
                ebay: 'Maika',
                stobbe: 'Stobbe',
                wtr_finn: 'Weiterleitung an Finn',
                wtr_lafazit: 'Weiterleitung an Lafazit',
                // claudia: 'Weiterleitung an Claudia',
                dena_tech: 'Dena Tech',
                wtr_mahmud: 'Weiterleitung an Mahmud'
            }
        },
        tagsNames() {
            return Object.values(this.tags);
        },
        tagsValues() {
            return Object.keys(this.tags);
        }
    },
    watch: {
        contentValue: function(newVal, oldVal) {
            if(newVal.length > 0) {
                this.error = '';
            }
        },
        ticket: function(newVal, oldVal) {
            if(newVal != null) {
                this.getAmazonDetails();
                this.setLoading(false);
                this.$nextTick(() => {
                    this.updateStarStatus(); // Ensure DOM updates are complete
                });
            }
        }
    }
  }
</script>